export default {
    ["Установить"]: "Установить",
    ["Главная"]: 'Главная',
    ["Новости"]: 'Новости',
    ["Комплексная программное обеспечения"]: 'Сэкономит Ваше время и деньги',
    ["Автоматизация производственных процессов и бизнес задач"]: 'Высококачественное программное решение для управления Вашим бизнес-процессом',
    ["Увеличьте чистую прибыль, уменьшив до 30% рабочих сил. Повышайте эффективность производства, контролируя процесс через Apoint."]: 'Нужна подробная информация? Прокрутите вниз или свяжитесь с нами.',
    ["Получить консультацию"]: 'Связаться с консультантом',
    ["5stepdescription"]: 'Программа цифровизирует механические процессы, минимизирует человеческий фактор и анализирует воздействие внешних и внутренних факторов на бизнес',
    ["5steptitle"]: 'Автоматизируйте свои бизнес-процессы полностью или частично с Apoint',
    ["ERP"]: 'ERP',
    ["Автоматизация производственных процессов"]: 'Автоматизация производственных процессов',
    ["СКЛАД"]: 'СКЛАД',
    ["Весь ваш склад на ваших руках"]: 'Весь ваш склад на ваших руках',
    ["HR"]: 'HR',
    ["Контроль и учет посещаемости ваших сотрудников"]: 'Контроль и учет посещаемости ваших сотрудников',
    ["KPI"]: 'KPI',
    ["Коэффициент, определяющий эффективность работы предприятия"]: 'Коэффициент, определяющий эффективность работы предприятия',
    ["CRM"]: 'CRM',
    ["Система управления взаимоотношениями с клиентами"]: 'Система управления взаимоотношениями с клиентами',
    ["Позвоните или оставьте ваши контакты, мы сами свяжемся с вами"]: 'Позвоните или оставьте свои контакты, мы сами свяжемся с Вами',
    ["Ваше имя"]: 'Ваше имя',
    ["Номер телефона"]: 'Номер телефона',
    ["Позвоните"]: 'Позвоните',
    ["Что можно достич с помочю Apoint?"]: 'Чего можно достичь с помощью Apoint?',
    ["Мониторинг производственных процессов на 100%"]: 'Мониторинг производственных процессов на 100%',
    ["Автоматизация любых видов оказаемых услуг на 100%"]: 'Автоматизация любых видов оказываемых услуг на 100%',
    ["Уменшение и отслежование истории бракованных продуктов"]: 'Уменшение и отслежование истории бракованных продуктов',
    ["Время обслуживание покупателей сократилось в 3 раза"]: 'Время обслуживания покупателей сократится в 3 раза',
    ["Товарные излишки на складе сократились на 25%"]: 'Товарные излишки на складе сократятся на 24%',
    ["Постоянное наличие нужного сыря и ассортимента приблизилось к 100%"]: 'Постоянное наличие нужного сырья и ассортимента приблизится к 100%',
    ["Расчет заработной платы сотрудника сократилось на 99%"]: 'Расчет заработной платы сотрудника сократится на 99%',
    ["Все необходимые отчеты у вас на ладони"]: 'Все необходимые отчеты у вас на ладони',
    ["За счет чего достигается такой результат?"] : 'За счет чего достигается такой результат?',
    ["Свяжитесь с нами и мы подъедим"]: 'Свяжитесь с нами и мы подъедим',
    ["Оставьте свой номер телефона – мы перезвоним вам и проконсультируем по всем вопросам!"] : 'Оставьте номер для консультации',
    ["Телефон номер"]: 'Номер телефона',
    ["Перезвонить вам"]: 'Перезвонить мне',
    ["Сокращение хищений товаров со стороны сотрудников"]: 'Сокращение хищений товаров со стороны сотрудников',
    ["Полный контроль и учет сырья в производстве"]: 'Полный контроль и учет сырья в производстве',
    ["Добавление мобильных складов без ограничений"]: 'Добавление мобильных складов без ограничений',
    ["Внедрение системы лояльности и скидочных карт"]: 'Внедрение системы лояльности и скидочных карт',
    ["Учет посещаемости и рабочий график сотрудников"]: 'Учет посещаемости и рабочего графика сотрудников',
    ["Расширенный модуль бухгалтерии"]: 'Расширенный модуль бухгалтерии',
    ["Составление и контроль этапов производства"]: 'Составление и контроль этапов производства',
    ["Автоматизация продажи товаров и услуг"]: 'Автоматизация продажи товаров и услуг',
    ["История и мониторинг заказов производства"]: 'История и мониторинг заказов производства',
    ["Увеличение скорости и улучшение качества обслуживания"]: 'Увеличение скорости и улучшение качества обслуживания',
    ["Полная история клиента"]: 'Полная история клиента',
    ["Сокращение времени на проверки и ревизии"]: 'Сокращение времени на проверки и ревизии',
    ["История выполненных задач сотрудников"]: 'История выполненных задач сотрудников',
    ["Анализ и понимание того, какие 20% товаров приносят 80% прибыли"]: 'Анализ и понимание того, какие товары приносят 80% прибыли',
    ["Сокращение числа ошибок и предотвращение обсчета покупателей"]: 'Сокращение числа ошибок и предотвращение обсчета покупателей',
    ["Оперативный контроль остатков в 1 клик"]: 'Оперативный контроль остатков в 1 клик',
    ["Инвентаризация имущества организации"]: 'Инвентаризация имущества организации',
    ["Составление структуры компании от отделов до оборудования"]: 'Составление структуры компании от отделов до оборудования',
    ["4 приложение поможет вам стать более мобильным"]: '4 приложения, которые помогут Вам стать более мобильным',
    ["Программа уже готово для следуюших направлений"]: 'Готовые программы для следующих направлений',
    ["Установить немедленно"]: 'Установить немедленно',
    ["Трикотажные фабрики"]: 'Трикотажные фабрики',
    ["Производство мебели"]: 'Производство мебели',
    ["Производство DSP и ЛДСП"]: 'Производство ДСП и ЛДСП',
    ["Что говорят о нас клиенты"]: 'Что говорят о нас клиенты',
    ["Новости и публикации"]: 'Новости и публикации',
    ["Все, что касается Apoint и автоматизации бизнес процессов и производства"]: 'Все, что касается Apoint и автоматизации бизнес процессов и производства',
    ["Ну что скажите? Автоматизируем?"]: 'Оставьте номер, чтобы автоматизировать свой бизнес',
    ["Оставьте ваше номер телефона, и мы сами вам позвоним, обясним и подедим"]: 'Оставьте Ваш номер телефона, мы сами Вам перезвоним и объясним.',
    ["Самый быстрый и простой способ создания растущих бизнес-решений с помощью наших продуктов"]: 'Самый быстрый и простой способ создания растущих бизнес-решений с помощью наших продуктов',
    ["Напишите нам в телеграм"]: 'Напишите нам в телеграм',
    ["© 2022 Apoint Все права защищены"]: '© 2022 Apoint Все права защищены',
    ["Заявка успешно отправлено"]: 'Заявка успешно отправлено',
    ["Мы очень рады что вы не оставили наше предложение без внимание"]: 'Мы очень рады что вы не оставили наше предложение без внимание',
    ["Бесплатная консультация"]: 'Бесплатная консультация',
    ["Оставьте свои данные и мы свяжемся с вами как можно скорее. И подробно расскажем про автоматизацию производства"]: 'Оставьте свои данные и мы свяжемся с вами как можно скорее. И подробно расскажем про автоматизацию производства',
    ['Перезвоните мне']: 'Перезвонить мне',
    ['Если Вы не хотите ждать от нас звонка, Вы можете позвонить нам прямо сейчас по номеру:']: 'Если Вы не хотите ждать от нас звонка, Вы можете позвонить нам прямо сейчас по номеру:',
    ["Нам доверяют"]: "Нам доверяют"
  }
  