import React, { useEffect, useState } from 'react';
import { BrowserRouter,Routes,Route,} from "react-router-dom";
import Chatra  from '@chatra/chatra';
import Footer from "./components/Footer";
import Header from "./components/Header";
import Modal from "./components/Modal";
import Home from "./pages/home/index";
import Posts from "./pages/posts/index";
import Post from "./pages/posts/_slug";
import 'swiper/css';
import  "./styles/main.css";
import  "./styles/fonts.css";
import  "./styles/style.css";

let config = {
  ID: 'v3vMyB7TaBAN39tiC'
}


Chatra('init', config)

const App = () => {
  const [loading,setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
        setLoading(false)
    }, 1400);
  },[])
  return (
   
      <BrowserRouter >
         { 
          loading ? <> <div className='homeloader'>
             <div className='homespinner' />
             <h1 className="font-acrom-medium">Apoint.uz saytiga hush kelibsiz</h1>
      </div></> :<>
           <Header />
           <Routes>
              <Route exact  path="/" element={<Home/>} />
              <Route exact  path="/posts"  element={<Posts/>}/>
              <Route exact  path="/posts/:name"  element={<Post/>}/>
         </Routes>
         <Footer/>
         <Modal/></>}
     </BrowserRouter>
    
  );
}

export default App;
