import { configureStore } from '@reduxjs/toolkit'
import menus from "./slices/menus";
import feedback from "./slices/feedback";
import auth from "./slices/auth";
export const store = configureStore({
  reducer: {
    menus,
    feedback,
    auth
  },  
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
       serializableCheck: false,
  }),
})