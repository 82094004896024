import {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector, useDispatch} from 'react-redux';
import {openModal} from "../../store/slices/feedback";

const Index = () => {

    const [videoModalVisible, setvideoModalVisible] = useState(false);
    const [videoSrc, setVideoSrc] = useState("https://www.youtube.com/embed/KIi-hcgLosY?autoplay=0")
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const video = useRef();

    const closeVideoModal = () => {
        setvideoModalVisible(false);
        video.current.src = '';
        setTimeout(() => {
            video.current.src = videoSrc       
        },100)
        
    }

    const openVideoModal = () => {
        setvideoModalVisible(true)
    }

    const openMyModal = () => {
        dispatch(openModal())
    }
    return (
        <section className="introduction">

            <section className={`modal consult video_modal ${videoModalVisible ? 'show' : ''}`}>
                <div className="modal--overlay" onClick={closeVideoModal}></div>
                <div className="holder">
                    <div className="content br-15">
                        <div className="video-modal-wrapper">
                            <iframe ref={video}   width="1248" height="702"
                                    src={videoSrc}
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                        </div>
                        <div className="modal-close" onClick={closeVideoModal}></div>
                    </div>
                </div>
            </section>

            <div className="content-backdrop">
                <div className="container">
                    <div className="content-back">

                        <div className="introvideo-modal" onClick={openVideoModal}>
                            <img src="/assets/images/introvideo-modal.jpg" alt="person"/>
                            <div className="btn-play">
                                <img src="/assets/images/icons/play.svg" alt="play"/>
                            </div>
                        </div>

                        <div className="backdrop-img">
                            <img src="/assets/images/introvideo-bg.png" alt="bg"/>
                        </div>

                    </div>
                </div>
            </div>

            <div className="content-main">
                <div className="container">
                    <div className="inner-content">
                        <a className="intro-makecall" href="tel:+998781132001">``
                            <img src="/assets/images/icons/phone.svg" alt="phone"/>
                            <p><span>+998</span>(78) 113 20 01</p>
                        </a>

                        <div className="texting">
                            <p>{t("Комплексная программное обеспечения")}</p>
                            <h1 className="title">{t("Автоматизация производственных процессов и бизнес задач")}</h1>
                            <p className="framed">
                                {
                                    t("Увеличьте чистую прибыль, уменьшив до 30% рабочих сил. Повышайте эффективность производства, контролируя процесс через Apoint.")
                                }
                            </p>
                        </div>

                        <button className="btn-defaultsqr" onClick={openMyModal}>{t("Получить консультацию")}</button>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default Index;
