
import React from "react";
import { useMediaQuery } from 'react-responsive'


const SingleNews = (props) => {

  const isDesktopOrLaptop = useMediaQuery({
    query: '(max-width: 767px)'
  })

	return (
		<> {
      !props.single_news ? <div className="skeleton__single-news single-news animation-part">
			<div className="single-news__top ">
				<div className="single-news__img" />
				<div className="single-news__title-wrapper">
				</div>
			</div>
			<div className="single-news__bottom">
				{[...Array(3)].map((_, key) => (
					<div className="single-news__title" />
				))}
			</div>
		</div> : <div className="skeleton__single-news single-news animation-part single">
    <div className="single-news__title skeleton_news_title" />
			<div className="single-news__top ">
				<div className="single-news__img" />
				<div className="single-news__title-wrapper">
				</div>
			</div>
			<div className="single-news__bottom single_bottom">
				{[...Array(10)].map((_, key) => (
					<div className="single-news__title" />
				))}
			</div>
		</div>
    }
    </>
	);
};

export default SingleNews;
