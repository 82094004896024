import {useState} from "react"
import {useTranslation} from "react-i18next";
import {useSelector, useDispatch} from 'react-redux';
import InputMask from "react-input-mask";
import {closeModal} from "../store/slices/feedback";
import {fetchFeedback} from "../store/slices/feedback";


const Index = () => {

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");

    const {t, i18n} = useTranslation()
    const stateFeedback = useSelector(state => state.feedback)
    const dispatch = useDispatch()

    const close = () => {
        dispatch(closeModal())
    }

    const onSubmit = (event) => {
        event.preventDefault()
        const data = {
            name: name,
            phone: phone,
            type: 1
        }
        const args = {
            data,
            is_only_success: true
        }
        dispatch(fetchFeedback(args))
    }
    return (
        <section className={`modal consult ${stateFeedback.modalVisible ? 'show' : ''}`}>
            <div className="holder">
                <div className="modal--overlay" onClick={close}></div>

                <div className="content"><>
                    {stateFeedback.isSuccess ?
                        <div className="success-content">
                            <h3>{t("Заявка успешно отправлено")}</h3><img src="/assets/images/modals/success.png"
                                                                          alt="success"/>
                            <p>{t("Мы очень рады что вы не оставили наше предложение без внимание")}</p>
                            <button className="btn-defaultsqr" onClick={close}>Ок</button>
                        </div> :

                        <form
                            className="form"
                            onSubmit={onSubmit}
                        >
                            <h3>{t("Бесплатная консультация")}</h3>
                            <p>{t("Оставьте свои данные и мы свяжемся с вами как можно скорее. И подробно расскажем про автоматизацию производства")}</p>

                            <input
                                type="text"
                                placeholder={t('Ваше имя')}
                                onChange={(e) => setName(e.target.value)}
                                required="required"
                            />
                            <InputMask
                                 mask="99 999 99 99"
                                placeholder={t('Телефон номер')}
                                onChange={(e) => setPhone(e.target.value)}
                                required="required"
                            />

                            <button className={`btn-defaultsqr ${stateFeedback.loading ? 'loading' : ''}`}
                                    type="submit">{t("Перезвоните мне")}</button>

                            <p className="contact">{t("Если Вы не хотите ждать от нас звонка, Вы можете позвонить нам прямо сейчас по номеру")}:</p>
                            <a href="tel:+998781132001">+99878 113 20 01</a>

                        </form>
                    }
                </>
                    <div className="img">
                        <img src="/assets/images/modals/1.jpg" alt="modal"/>
                        <div className="logo">
                            <img src="/assets/images/modals/logo.png" alt="logo"/>
                        </div>
                    </div>
                    <div className="modal-close" onClick={close}></div>

                </div>
            </div>
        </section>

    )
}

export default Index;
