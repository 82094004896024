import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { openModal } from "../../store/slices/feedback";
import first from "../../styles/images/content/offer-icon-1.svg";
import second from "../../styles/images/content/offer-icon-2.svg";
import third from "../../styles/images/content/offer-icon-3.svg";
import four from "../../styles/images/content/offer-icon-4.svg";
import last from "../../styles/images/content/offer-icon-5.svg";

const Index = () => {
  const { t, i18n } = useTranslation();
  const [items, setItems] = useState([
    {
      title: "ERP",
      desc: "Автоматизация производственных процессов",
      icon: first,
      key: "erp",
    },
    {
      title: "СКЛАД",
      desc: "Весь ваш склад на ваших руках",
      icon: second,
      key: "storage",
    },
    {
      title: "HR",
      desc: "Контроль и учет посещаемости ваших сотрудников",
      icon: third,
      key: "hr",
    },
    {
      title: "KPI",
      desc: "Коэффициент, определяющий эффективность работы предприятия",
      icon: four,
      key: "kpi",
    },
    {
      title: "CRM",
      desc: "Система управления взаимоотношениями с клиентами",
      icon: last,
      key: "crm",
    },
  ]);
  const [currentLang, setcurrentLang] = useState(i18n.language);
  const [activeItem, setactiveItem] = useState(null);
  const [modalVisible, setmodalVisible] = useState(false);
  const dispatch = useDispatch();

  const openModalFeedback = () => {
    dispatch(openModal());
  };
  const openmyModal = (name) => {
    setmodalVisible(true);
    setactiveItem(name);
  };
  const closeModal = () => {
    setmodalVisible(false);

    setTimeout(() => {
      setactiveItem(null);
    }, 500);
  };
  console.log({ currentLang });
  console.log(i18n);
  return (
    <section className="offers">
      <div className="container">
        <div className="cards_row">
          <div className="first">
            <h2>{t("5steptitle")}</h2>
            <p>{t("5stepdescription")}</p>
            <div
              className="btn-defaultsqr display-inline"
              onClick={openModalFeedback}
            >
              {t("Получить консультацию")}
            </div>
          </div>

          <>
            {items.map((item, index) => (
              <div
                className="card"
                key={item.key}
                onClick={() => openmyModal(item.key)}
              >
                <div className="card-content">
                  <div className="icon">
                    <img src={item.icon} alt="icon" />
                  </div>
                  <p className="title">{t(item.title)}</p>
                  <p>{t(item.desc)}</p>
                  <button className="btn-more">
                    <img
                      src="/assets/images/icons/arrow-more.svg"
                      alt="arrow"
                    />
                  </button>
                </div>
              </div>
            ))}
          </>
        </div>
      </div>

      <section
        className={
          modalVisible && activeItem === "erp"
            ? "modal description show"
            : "modal description "
        }
      >
        <div className="holder">
          <div className="modal--overlay" onClick={closeModal}></div>
          <div className="content">
            <div className="desc-content">
              <h3>ERP</h3>

              <div className="text-content">
                <p>
                  {t(
                    "Программное обеспечение, которое позволит интегрировать производственный процесс с операциями,управления трудовыми ресурсами, финансового менеджмента и управления активами.Программа полностью формирует отчетность процесса производства Вашего предприятия. Программа, используя данные своей базы, рассчитывает количество потребляемого сырья для каждого продукта производства, тем самым сокращает Ваше время подсчета"
                  )}
                </p>
                <p>
                  {t(
                    "Также, вы уже не будете зависеть от менеджера производства по факту контроля дисциплины, производительности труда и количества брака сотрудников. Единожды введя в программу распределение должностей и структуру модели, вы получите со стороны программы распределение обязанностей каждого работника и контроль выполнения таковых. Как результат – усиление самоконтроля со стороны работников, так как от этого зависит их заработная плата и прочие материальные поощрения"
                  )}
                </p>
              </div>
            </div>

            <div className="img">
              <img src="/assets/images/modals/erp-bg.jpg" alt="modal" />

              <div className="logo">
                <img src="/assets/images/modals/logo.png" alt="logo" />
              </div>
            </div>

            <div className="modal-close" onClick={closeModal}></div>
          </div>
        </div>
      </section>

      <section
        className={
          modalVisible && activeItem === "storage"
            ? "modal description show"
            : "modal description "
        }
      >
        <div className="holder">
          <div className="modal--overlay" onClick={closeModal}></div>
          <div className="content">
            <div className="desc-content">
              <h3>{t("СКЛАД")}</h3>
              <div className="text-content">
                <p>
                  {t(
                    "APOINT объединяет в себе важнейшие приложения для производителя. Склад, в свою очередь включающий в себя:"
                  )}
                </p>
                <p></p>
                <ul>
                  <li>{t("Сырьевой склад")}</li>
                  <li>{t("Склад готовой продукции")}</li>
                  <li>{t("Мобильный склад")}</li>
                </ul>
                <p>
                  {t(
                    "Важно отметить, что Вы получаете возможность удаленного управления вашими мобильными складами."
                  )}
                </p>
              </div>
            </div>

            <div className="img">
              <img src="/assets/images/modals/storage-bg.jpg" alt="modal" />

              <div className="logo">
                <img src="/assets/images/modals/logo.png" alt="logo" />
              </div>
            </div>

            <div className="modal-close" onClick={closeModal}></div>
          </div>
        </div>
      </section>

      <section
        className={
          modalVisible && activeItem === "hr"
            ? "modal description show"
            : "modal description "
        }
      >
        <div className="holder">
          <div className="modal--overlay" onClick={closeModal}></div>
          <div className="content">
            <div className="desc-content">
              <h3>{t("HR - Отдел кадров")}</h3>

              <div className="text-content">
                <p>
                  {t("Автоматизирует работу подбора и распределения кадров.")}
                </p>
                <p>
                  {t(
                    "Оценивает навыки каждого, давая возможность раскрыться потенциалу каждого сотрудника."
                  )}
                </p>
                <p>
                  {t(
                    "Программа организует и координирует деятельность кадровой службы, распределяет сотрудников по местам, разрабатывает и внедряет систему эффективной адаптации новых работников, планирует программу увеличения производительности труда в компании и многое другое."
                  )}
                </p>
              </div>
            </div>

            <div className="img">
              <img src="/assets/images/modals/hr-bg.jpg" alt="modal" />

              <div className="logo">
                <img src="/assets/images/modals/logo.png" alt="logo" />
              </div>
            </div>

            <div className="modal-close" onClick={closeModal}></div>
          </div>
        </div>
      </section>

      <section
        className={
          modalVisible && activeItem === "kpi"
            ? "modal description show"
            : "modal description "
        }
      >
        <div className="holder">
          <div className="modal--overlay" onClick={closeModal}></div>
          <div className="content">
            <div className="desc-content">
              <h3>KPI</h3>

              <div className="text-content">
                <p>
                  {t(
                    "Коэффициент — инструмент позволяющий контролировать и оценивать работу сотрудников, отдела, сектора и всего бизнеса в целом на соответствие поставленным целям. Программа помогает в решении премировании сотрудников задостижение результатов"
                  )}
                </p>
                <p></p>
              </div>
            </div>

            <div className="img">
              <img src="/assets/images/modals/kpi-bg.jpg" alt="modal" />

              <div className="logo">
                <img src="/assets/images/modals/logo.png" alt="logo" />
              </div>
            </div>

            <div className="modal-close" onClick={closeModal}></div>
          </div>
        </div>
      </section>

      <section
        className={
          modalVisible && activeItem === "crm"
            ? "modal description show"
            : "modal description "
        }
      >
        <div className="holder">
          <div className="modal--overlay" onClick={closeModal}></div>

          <div className="content">
            <div className="desc-content">
              <h3>CRM</h3>
              {currentLang === "ru" ? (
                <div className="text-content">
                  <p>
                    {t(
                      "Система управления взаимоотношениями с клиентами, управляет любой контактной информацией между продавцом и покупателем, производителем и потребителем. Благодаря CRM-системе производитель получает следующие данные о:"
                    )}
                  </p>
                  <ul>
                    <li>{t("продукции с наибольшим спросом;")}</li>
                    <li>{t("реакции потребителя на товар;")}</li>
                    <li>{t("соотношении прошлых и будущих затрат/выгод;")}</li>
                    <li>{t("возможность мобильного изменения стратегии;")}</li>
                    <li>{t("на связи с потребителями 24/7")}</li>
                  </ul>
                </div>
              ) : (
                <div className="text-content">
                  <p>
                    Мижозлар билан муносабатларни бошқариш тизими, сотувчи ва
                    харидор, ишлаб чиқарувчи ва истеъмолчи ўртасидаги ҳар қандай
                    аълоқа маълумотларини бошқаради. СРМ тизими туфайли ишлаб
                    чиқарувчи қуйидаги афзалликларга эга:
                  </p>
                  <ul>
                    <li>
                      қайси маҳсулотга талаб катта эканлиги ҳақида маълумот;
                    </li>
                    <li>
                      харидор маҳсулотга қандай муносабатда бўлиши ҳақида
                      маълумот;
                    </li>
                    <li>ӯтган ва келгуси харажатлар, фойда, солиштириш;</li>
                    <li>мобил стратегиясини ўзгартириш имконияти;</li>
                    <li>истеъмолчилар билан 24/7 аълоқада бўлиш.</li>
                  </ul>
                </div>
              )}
            </div>

            <div className="img">
              <img src="/assets/images/modals/crm-bg.jpg" alt="modal" />

              <div className="logo">
                <img src="/assets/images/modals/logo.png" alt="logo" />
              </div>
            </div>

            <div className="modal-close" onClick={closeModal}></div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Index;
