import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {get} from "../../helpers/api";
import ContentLoader from "../../components/ContentLoader"

const Index = () => {
    const {name} = useParams();
    const [loading, setloading] = useState(false);
    const [newsData, setnewsData] = useState({});
    const {t} = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        window.scroll(0,0)
        setloading(true)
        get(`posts/${name}?include=photo`).then(res => {
            setnewsData(res.data);
            setloading(false);
        })
            .catch(e => {
                setloading(false)
            })
    }, [name])
    return (
        <section className="newspage post_page">
            {
                loading ? <div className="loader_wrapper"><ContentLoader single_news={true} /></div> : <>
 
                    {newsData &&
                        <div className="instant-view single-content">
                            <article>
                                {newsData.photo &&
                                    <div className="section-background">
                                        <img className="section-backgroundImage" alt={""}
                                             src={newsData.photo.thumbnails.low.src}/>
                                    </div>
                                }
                                <h1 dangerouslySetInnerHTML={{__html: newsData.title}}/>
                                <h2 dangerouslySetInnerHTML={{__html: newsData.content}}/>
                            </article>
                        </div>
                    }

                    <div className="container">

                        {newsData &&
                            <div className="titling">
                                {newsData.photo &&
                                    <div className="img">
                                        <img src={newsData.photo.thumbnails.low.src} alt="news"/>
                                    </div>
                                }

                                <div className="title">
                                    <span className="category" onClick={() => navigate('/posts')}>{t("Новости")}</span>
                                    <h1 dangerouslySetInnerHTML={{__html: newsData.title}}/>
                                </div>
                            </div>
                        }

                        {newsData &&
                            <div className="content content-style"
                                 dangerouslySetInnerHTML={{__html: newsData.content}}/>
                        }

                    </div>
                </>
            }
        </section>
    )
}

export default Index;
