import axios from 'axios'
import { createBrowserHistory } from 'history';
import { store } from '../store/index'
let token = localStorage.getItem('token')
const history = createBrowserHistory();


const instance = axios.create({
    baseURL: 'https://api.apoint.uz/api/v1/'
})

instance.defaults.headers.common['Accept'] = 'application/json';
instance.defaults.headers.common['Cache-Control'] = 'no-cache';
instance.defaults.headers.common['Content-Type'] = 'text/plain';



 instance.interceptors.request.use(config => {
    const lang = store.getState().auth.lang;
    config.params =  {
     _l:  lang,
     _f:'json',
     ...config.params,
    }
     return config;
 })


const get = makeActionDecorator(function (url) {
    return instance({
        method: 'GET',
        url,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
});


const post = makeActionDecorator(function (url, payload) {
    return instance({
        method: 'POST',
        url,
        data: payload,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
});

const put = makeActionDecorator(function (url, payload) {
    return instance({
        method: 'PUT',
        url,
        data: payload,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
});


const del = makeActionDecorator(function (url) {
    return instance({
        method: 'DELETE',
        url,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
});


function makeActionDecorator(fTargetFunction) {
    return function () {
        return fTargetFunction.apply(this, arguments);
    }
}

export {
    get,
    post,
    del,
    put,
}