import {useState} from "react";
import {useTranslation} from "react-i18next";

const Index = () => {
    const [items, setItems] = useState([
        {id: 1, title: "Мониторинг производственных процессов на 100%"},
        {id: 2, title: "Автоматизация любых видов оказаемых услуг на 100%"},
        {id: 3, title: "Уменшение и отслежование истории бракованных продуктов"},
        {id: 4, title: "Время обслуживание покупателей сократилось в 3 раза"},
        {id: 5, title: "Товарные излишки на складе сократились на 25%"},
        {
            id: 6,
            title:
                "Постоянное наличие нужного сыря и ассортимента приблизилось к 100%"
        },
        {id: 7, title: "Расчет заработной платы сотрудника сократилось на 99%"},
        {id: 8, title: "Все необходимые отчеты у вас на ладони"}
    ]);
    const {t, i18n} = useTranslation();

    return (
        <section className="achievements">
            <div className="container">
                <div className="img">
                    <img
                        className="bg"
                        src="/assets/images/achievements-bg.svg"
                        alt="achievements"
                    />
                    <img
                        className="person"
                        src="/assets/images/achievements.png"
                        alt="achievements"
                    />
                </div>

                <div className="text">
                    <h2 className="text-title-st">
                        {t("Что можно достич с помочю Apoint?")}
                    </h2>
                    <ul>
                        {items.map((item, index) => (
                            <li key={index}>{t(item.title)}</li>
                        ))}
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default Index;
