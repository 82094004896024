export default {
  ["Установить"]: "Ўрнатиш",
  ["Главная"]: "Бош саҳифа",
  ["Новости"]: "Янгиликлар",
  ["Комплексная программное обеспечения"]: "Комплексли дастурий таъминот",
  ["Автоматизация производственных процессов и бизнес задач"]:
    "Ишлаб чиқариш жараёнлари ва бизнес топшириқларни автоматлаштириш",
  ["Увеличьте чистую прибыль, уменьшив до 30% рабочих сил. Повышайте эффективность производства, контролируя процесс через Apoint."]:
    "Ишчи кучини 30% гача камайтириш орқали соф фойдани кўпайтиринг. APOINT иловаси ёрдамида назорат қилиш орқали ишлаб чиқариш самарадорлигини оширинг.",
  ["Получить консультацию"]: "Бепул консультация олиш",
  ["5stepdescription"]:
    "Дастур инсон омили устунлик қилаётган механик жараёнларни тўлиқ рақамлаштириш имконини беради. Шу билан бирга, ҳар хил омиллар бизнесга қандай таъсир кўрсатаётганини кузатиш мумкин.",
  ["5steptitle"]:
    "Бизнесингизни бугуноқ автоматлаштиринг ва рақобатда беш қадам олдинда бўлинг",
  ["ERP"]: "ERP",
  ["Автоматизация производственных процессов"]:
    "Ишлаб чиқариш жараёнларини автоматлаштириш",
  ["СКЛАД"]: "Омбор",
  ["Весь ваш склад на ваших руках"]: "Бутун омбор сизнинг қўлингизда",
  ["HR"]: "HR",
  ["Контроль и учет посещаемости ваших сотрудников"]:
    "Ходимларингиз ташрифини назорат қилиш ва ҳисоблаш",
  ["KPI"]: "KPI",
  ["Коэффициент, определяющий эффективность работы предприятия"]:
    "Корхона иш фаолиятининг самарадорлигини аниқловчи коэффициент",
  ["CRM"]: "CRM",
  ["Система управления взаимоотношениями с клиентами"]:
    "Мижозлар билан ўзаро муносабатлар тизими",
  ["Позвоните или оставьте ваши контакты, мы сами свяжемся с вами"]:
    "Қўнғироқ қилинг ёки рақамингизни қолдиринг, ўзимиз сиз билан боғланамиз",
  ["Ваше имя"]: "Исмингиз",
  ["Номер телефона"]: "Телефон рақамингиз",
  ["Позвоните"]: "Юбориш",
  ["Что можно достич с помочю Apoint?"]:
    "APOINT дастури орқали нималарга эришиш мумкин?",
  ["Мониторинг производственных процессов на 100%"]:
    "Ишлаб чиқариш жараёнларини мониторинг қилиш",
  ["Автоматизация любых видов оказаемых услуг на 100%"]:
    "Ҳар қандай хизмат турларини автоматлаштириш",
  ["Уменшение и отслежование истории бракованных продуктов"]:
    "Яроқсиз маҳсулотлар сонини камайтириш ва уларнинг сабабини аниқлаш",
  ["Время обслуживание покупателей сократилось в 3 раза"]:
    "Мижозларга хизмат кўрсатиш вақтини 3 баробаргача камайтириш",
  ["Товарные излишки на складе сократились на 25%"]:
    "Омбордаги ортиқча товар миқдорини 25% гача камайтириш",
  ["Постоянное наличие нужного сыря и ассортимента приблизилось к 100%"]:
    "Хомашё ва ассортиментнинг доимий мавжудлигини 100% таъминлаш",
  ["Расчет заработной платы сотрудника сократилось на 99%"]:
    "Ходимлар иш ҳақини ҳисоб-китоб қилишни 99% га қисқартириш",
  ["Все необходимые отчеты у вас на ладони"]:
    "Барча керакли ҳисоботларнинг ўзингизда бўлиши",
  ["За счет чего достигается такой результат?"]:
    "Бу натижага қандай эришиш мумкин?",
  ["Свяжитесь с нами и мы подъедим"]: "Свяжитесь с нами и мы подъедим",
  ["Оставьте свой номер телефона – мы перезвоним вам и проконсультируем по всем вопросам!"]:
    "Телефон рақамингизни қолдиринг – биз сизга ўзимиз қўнғироқ қилиб, керакли бўлган барча тавсияларни берамиз!",
  ["Телефон номер"]: "Телефон рақамингиз",
  ["Перезвонить вам"]: "Юбориш",
  ["Сокращение хищений товаров со стороны сотрудников"]:
    "Ходимлар томонидан маҳсулот ўғирланишини чеклаш",
  ["Полный контроль и учет сырья в производстве"]:
    "Ишлаб чиқаришда хомашёни ҳисобга олиш ва тўлиқ назорат қилиш",
  ["Добавление мобильных складов без ограничений"]:
    "Мобиль омборларни исталганча қўшиш",
  ["Внедрение системы лояльности и скидочных карт"]:
    "Чегирма карталар ва содиқлик тизимларини жорий этиш",
  ["Учет посещаемости и рабочий график сотрудников"]:
    "Ходимларнинг иш жадвали ва ташрифини қайд этиш",
  ["Расширенный модуль бухгалтерии"]: "Бухгалтериянинг кенгайтирилган модули",
  ["Составление и контроль этапов производства"]:
    "Ишлаб чиқариш босқичларини тузиш ва назорат қилиш",
  ["Автоматизация продажи товаров и услуг"]:
    "Хизматлар ва маҳсулот савдосини автоматлаштириш",
  ["История и мониторинг заказов производства"]:
    "Корхона буюртмаларининг тарихи ва мониторинги",
  ["Увеличение скорости и улучшение качества обслуживания"]:
    "Хизмат кўрсатиш тезлиги ва сифатини ошириш",
  ["Полная история клиента"]: "Мижозларнинг тўлиқ тарихи",
  ["Сокращение времени на проверки и ревизии"]:
    "Тафтиш ва текширувларга кетадиган вақтни қисқартириш",
  ["История выполненных задач сотрудников"]:
    "Ходимлар томонидан бажарилган вазифалар тарихи",
  ["Анализ и понимание того, какие 20% товаров приносят 80% прибыли"]:
    "Қандай товарлар 80% фойда келтиришини таҳлил қилиш ва тушуниш",
  ["Сокращение числа ошибок и предотвращение обсчета покупателей"]:
    "Харидорларни ҳисобда алдаш ва хатолар сонини камайтириш",
  ["Оперативный контроль остатков в 1 клик"]:
    "Биргина ҳаракат билан қолдиқларни оператив назорат қилиш",
  ["Инвентаризация имущества организации"]:
    "Ташкилот мулкини инвентаризация қилиш",
  ["Составление структуры компании от отделов до оборудования"]:
    "Бўлинмалардан жиҳозларгача компания тузилмасини яратиш",
  ["4 приложение поможет вам стать более мобильным"]:
    "4 илова сизга янада мобил бўлиш имконини беради",
  ["Программа уже готово для следуюших направлений"]:
    "Дастур қуйидаги йўналишлар учун тайёр",
  ["Установить немедленно"]: "Ҳозироқ ўрнатиш",
  ["Трикотажные фабрики"]: "Трикотаж фабрикалари",
  ["Производство мебели"]: "Мебель ишлаб чиқариш",
  ["Производство DSP и ЛДСП"]: "ДСП ва ЛДСП ишлаб чиқариш",
  ["Что говорят о нас клиенты"]: "Биз ҳақимизда мижозлар нима дейди?",
  ["Новости и публикации"]: "Мақола ва янгиликлар",
  ["Все, что касается Apoint и автоматизации бизнес процессов и производства"]:
    "APOINT ва ишлаб чиқариш соҳасини автоматлаштириш ҳақида барча янгиликлар",
  ["Ну что скажите? Автоматизируем?"]: "Нима дейсиз? Автоматлаштирамизми?",
  ["Оставьте ваше номер телефона, и мы сами вам позвоним, обясним и подедим"]:
    "Телефон рақамингизни қолдиринг.Биз сизни қизиқтираётган барча саволларга жавоб берамиз!",
  ["Самый быстрый и простой способ создания растущих бизнес-решений с помощью наших продуктов"]:
    "Apoint bilan biznesdagi muammolarni tez va oson hal qiling!",
  ["Напишите нам в телеграм"]: "Биз билан телеграмм орқали боғланинг",
  ["© 2022 Apoint Все права защищены"]:
    "© 2022 APOINT - Барча ҳуқуқлар ҳимояланган",
  ["Заявка успешно отправлено"]: "Ариза муваффақиятли юборилди",
  ["Мы очень рады что вы не оставили наше предложение без внимание"]:
    "Сиз бизнинг таклифимизни эътиборсиз қолдирмаганингиздан жуда хурсандмиз",
  ["Бесплатная консультация"]: "Бепул консультация",
  ["Оставьте свои данные и мы свяжемся с вами как можно скорее. И подробно расскажем про автоматизацию производства"]:
    "Маълумотларингизни қолдиринг ва сиз билан тезроқ боғланишга ҳаракат қиламиз. Ишлаб чиқаришни автоматлаштириш ҳақида сизни қизиқтирган  барча маълумотлар билан бўлишамиз!",
  ["Перезвоните мне"]: "Менга қўнғироқ қилинг",
  ["Если Вы не хотите ждать от нас звонка, Вы можете позвонить нам прямо сейчас по номеру:"]:
    "Агар сиз қўнғироғимизни кутишни истамасангиз, бизга ҳозироқ қуйидаги рақам орқали боғланишингиз мумкин",
  ["Программное обеспечение, которое позволит интегрировать производственный процесс с операциями,управления трудовыми ресурсами, финансового менеджмента и управления активами.Программа полностью формирует отчетность процесса производства Вашего предприятия. Программа, используя данные своей базы, рассчитывает количество потребляемого сырья для каждого продукта производства, тем самым сокращает Ваше время подсчета"]:
    "Ишлаб чиқариш жараёнини турли операциялар, ишчи кучи бошқаруви, активлар бошқаруви ва молиявий менежмент билан уйғунлаштирувчи дастурий таъминот.Дастур корхонангизнинг ишлаб чиқариш жараёни бўйича ҳисоботини тўлиқ шакллантиради. Дастур ўз маълумотлар базасидаги маълумотлардан фойдаланиб, ҳар бир ишлаб чиқариш маҳсулоти учун сарфланган хом ашё миқдорини ҳисоблаб чиқади ва шу билан сизнинг ҳисоб-китоб учун кетказадиган вақтингизни тежайди.",
  ["Также, вы уже не будете зависеть от менеджера производства по факту контроля дисциплины, производительности труда и количества брака сотрудников. Единожды введя в программу распределение должностей и структуру модели, вы получите со стороны программы распределение обязанностей каждого работника и контроль выполнения таковых. Как результат – усиление самоконтроля со стороны работников, так как от этого зависит их заработная плата и прочие материальные поощрения"]:
    "Вазифалар тақсимоти ва моделнинг тузилишини дастурга киритганингиздан сўнг, сиз дастурдан ҳар бир ходим учун вазифаларни тақсимланишини ва уларнинг бажарилишини назорат қила оласиз. Бунинг ортидан ходимларнинг ўз-ўзини назорат қилишлари (дисциплина) кучаяди, чунки иш ҳақи ва бошқа моддий рағбатлантириш шунга боғлиқ бўлади",
  ["СКЛАД"]: "ОМБОР",
  ["APOINT объединяет в себе важнейшие приложения для производителя. Склад, в свою очередь включающий в себя:"]:
    "APOINT ишлаб чиқарувчи учун энг муҳим иловаларни бирлаштиради:",
  ["Сырьевой склад"]: "Хом ашё омбори",
  ["Склад готовой продукции"]: "Тайёр маҳсулотлар омбори",
  ["Мобильный склад"]: "Мобил омбори",
  ["Важно отметить, что Вы получаете возможность удаленного управления вашими мобильными складами."]:
    "Эътиборлиси шундаки сиз мобил омборларингизни масофадан бошқариш имкониятига эга бўласиз.",
  ["HR - Отдел кадров"]: "HR - Кадрлар бўлими",
  ["Автоматизирует работу подбора и распределения кадров."]:
    "Меҳнат муносабатларининг схемасини автоматлаштиради, ҳар бир ходимнинг имкониятларини очиб беришга ёрдам беради.",
  ["Оценивает навыки каждого, давая возможность раскрыться потенциалу каждого сотрудника."]:
    "Кадрлар бўлими фаолиятини ташкил қилади ва мувофиқлаштиради;",
  ["Программа организует и координирует деятельность кадровой службы, распределяет сотрудников по местам, разрабатывает и внедряет систему эффективной адаптации новых работников, планирует программу увеличения производительности труда в компании и многое другое."]:
    "Кадрлар бўлими фаолиятини ташкил қилади ва мувофиқлаштиради, Ходимларни ўз жойларига тақсимлайди, Янги ходимларни самарали мослаштириш тизимини ишлаб чиқади ва жорий қилади, Корхонада меҳнат унумдорлигини ошириш дастурини режалаштиради.",
  ["Коэффициент — инструмент позволяющий контролировать и оценивать работу сотрудников, отдела, сектора и всего бизнеса в целом на соответствие поставленным целям. Программа помогает в решении премировании сотрудников задостижение результатов"]:
    "Компаниянинг операцион ёки стратегик мақсадларига эришиш доирасида корхона, ходим, бўлим ва фаолият самарадорлигини аниқлайдиган коэффициент.KPI тизими ёрдамида сиз бўлимингиз ёки компаниянгиз самарали ишлаётганини билиб олишингиз мумкин. Бизнинг дастурий таъминотимизнинг КPI модули ёрдамида сиз ходимни маълум натижага эришиш учун бонуслар, тўловлар ва нафақалар билан молиявий қизиқтириш имкониятига эга бўласиз",
  ["Система управления взаимоотношениями с клиентами, управляет любой контактной информацией между продавцом и покупателем, производителем и потребителем. Благодаря CRM-системе производитель получает следующие данные о:"]:
    "Мижозлар билан муносабатларни бошқариш тизими, сотувчи вахаридор, ишлаб чиқарувчи ва истеъмолчи ўртасидаги ҳар қандай аълоқа маълумотларини бошқаради. СРМ тизими туфайли ишлаб чиқарувчи қуйидаги афзалликларга эга:",
    ["продукции с наибольшим спросом;"]: "қайси маҳсулотга талаб катта эканлиги ҳақида маълумот;",
    ["реакции потребителя на товар;"]: "харидор маҳсулотга қандай муносабатда бўлиши ҳақида маълумот;",
    ["соотношении прошлых и будущих затрат/выгод;"]: "ӯтган ва келгуси харажатлар, фойда, солиштириш;",
    ["возможность мобильного изменения стратегии;"]: "мобил стратегиясини ўзгартириш имконияти;",
    ["на связи с потребителями 24/7"]: "истеъмолчилар билан 24/7 аълоқада бўлиш",
    ["Нам доверяют"]: "Бизнинг ҳамкорларимиз"
};
