
import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  menu: []
}

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    updateMenu: (state,menus) => {
        state.menus = menus
    }
  },
})

export const {updateMenu } = menuSlice.actions;

export default menuSlice.reducer