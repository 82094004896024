import {useRef, useState} from "react"
import {useTranslation} from "react-i18next";
import {useSelector, useDispatch} from 'react-redux';
import InputMask from "react-input-mask";
import {update_show_only_success} from "../../store/slices/feedback";
import {fetchFeedback} from "../../store/slices/feedback";
import Modal from "../Modal";


const Index = () => {


    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const {t, i18n} = useTranslation();
    const dispatch = useDispatch();
    const stateFeedback = useSelector(state => state.feedback);

    const phoneRef = useRef()
    const nameRef = useRef()

    const closeSuccessModal = () => {
        dispatch(update_show_only_success(false))
    }


    const onSubmit = (event) => {
        event.preventDefault()
        const data = {
            phone: phone,
            type: 2
        }
        const args = {
            data,
            is_only_success: 'contact'
        }
        dispatch(fetchFeedback(args))
        phoneRef.current.value = ''
        nameRef.current.value = ''
    }
    // InputMask mask="99 999 99 99" 

    return (
        <section className="contactus">
            <section
                className={`modal special_modal consult  ${stateFeedback.show_only_success === 'contact' ? 'show' : ''}`}>
                <div className="modal--overlay" onClick={closeSuccessModal}></div>
                <div className="holder">
                    <div className="content">
                        <div className="success-content">
                            <h3>{t("Заявка успешно отправлено")}</h3><img src="/assets/images/modals/success.png"
                                                                          alt="success"/>
                            <p>{t("Мы очень рады что вы не оставили наше предложение без внимание")}</p>
                            <button className="btn-defaultsqr" onClick={closeSuccessModal}>Ок</button>
                        </div>

                        <div className="modal-close" onClick={closeSuccessModal}></div>
                    </div>
                </div>
            </section>
            <h2>{t("Позвоните или оставьте ваши контакты, мы сами свяжемся с вами")}</h2>

            <div className="contactus-line">
                <div className="container">
                    <div className="img">
                        <div className="holder">
                            <img src="/assets/images/icons/logo-gradientblack.svg" alt="logo"/>
                        </div>
                    </div>

                    <form onSubmit={onSubmit} className="form">
                        <div className="input">
                            <img src="/assets/images/icons/input-name.svg" alt="name"/>
                            <InputMask   ref={nameRef} onChange={(e) => setName(e.target.value)}
                                   placeholder={t('Ваше имя')} required="required"/>
                        </div>
                        <div className="input">
                            <img src="/assets/images/icons/input-phone.svg" alt="phone"/>
                            <InputMask mask="99 999 99 99"  ref={phoneRef}  onChange={(e) => setPhone(e.target.value)}
                                   placeholder={t('Телефон номер')} required="required"/>
                        </div>
                        <button className={`${stateFeedback.loading ? 'loading' : ''} form-submit`}
                                type="submit">{t("Позвоните")}</button>
                    </form>
                </div>
            </div>
        </section>
    )
}

export default Index;
