import {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector, useDispatch} from 'react-redux';
import InputMask from "react-input-mask";
import {update_show_only_success} from "../../store/slices/feedback";
import {fetchFeedback} from "../../store/slices/feedback";


const Index = () => {

    const [phone, setPhone] = useState('')
    const [items, setItems] = useState([
        {id: 1, title: 'Сокращение хищений товаров со стороны сотрудников'},
        {id: 2, title: 'Полный контроль и учет сырья в производстве'},
        {id: 3, title: 'Добавление мобильных складов без ограничений'},
        {id: 4, title: 'Внедрение системы лояльности и скидочных карт'},
        {id: 5, title: 'Учет посещаемости и рабочий график сотрудников'},
        {id: 6, title: 'Расширенный модуль бухгалтерии'},
        {id: 7, title: 'Составление и контроль этапов производства'},
        {id: 8, title: 'Автоматизация продажи товаров и услуг'},
        {id: 9, title: 'История и мониторинг заказов производства'},
        {id: 10, title: 'Увеличение скорости и улучшение качества обслуживания'},
        {id: 11, title: 'Полная история клиента'},
        {id: 12, title: 'Сокращение времени на проверки и ревизии'},
        {id: 13, title: 'История выполненных задач сотрудников'},
        {id: 14, title: 'Анализ и понимание того, какие 20% товаров приносят 80% прибыли'},
        {id: 15, title: 'Сокращение числа ошибок и предотвращение обсчета покупателей'},
        {id: 16, title: 'Оперативный контроль остатков в 1 клик'},
        {id: 17, title: 'Инвентаризация имущества организации'},
        {id: 18, title: 'Составление структуры компании от отделов до оборудования'},
    ])
    const stateFeedback = useSelector(state => state.feedback)
    const dispatch = useDispatch()
    const {t, i18n} = useTranslation();
    const phoneRef = useRef()


    const closeSuccessModal = () => {
        dispatch(update_show_only_success(false))
    }

    const onSubmit = (event) => {
        event.preventDefault()
        const data = {
            phone: phone,
            type: 2
        }
        const args = {
            data,
            is_only_success: 'result'
        }
        dispatch(fetchFeedback(args))
        phoneRef.current.value = ''
    }
    return (
        <section className="resulsteps">
            <section className={`modal consult  special_modal ${stateFeedback.show_only_success === 'result' ? 'show' : ''}`}>
                <div className="modal--overlay" onClick={closeSuccessModal}></div>
                <div className="holder">
                    <div className="content">
                        <div className="success-content">
                            <h3>{t("Заявка успешно отправлено")}</h3><img src="/assets/images/modals/success.png"
                                                                          alt="success"/>
                            <p>{t("Мы очень рады что вы не оставили наше предложение без внимание")}</p>
                            <button className="btn-defaultsqr" onClick={closeSuccessModal}>Ок</button>
                        </div>
                        <div className="modal-close" onClick={closeSuccessModal}></div>
                    </div>
                </div>
            </section>

            <div className="container">
                <h2 className="text-title-st">{t("За счет чего достигается такой результат?")}</h2>

                <div className="items_row">
                    {items.map((item, index) => (
                        <div className="item" key={item.id}>
                            <div className="item-content">
                                <span>{index + 1}</span>
                                <p>{t(item.title)}</p>
                            </div>
                        </div>
                    ))

                    }
                </div>

                <div className="leaveinfo">
                    <p className="title">{t("Свяжитесь с нами и мы подъедим")}</p>
                    <p>{t("Оставьте свой номер телефона – мы перезвоним вам и проконсультируем по всем вопросам!")}</p>
                    <form onSubmit={onSubmit} className="form">
                        <InputMask mask="99 999 99 99" ref={phoneRef}  placeholder={t('Телефон номер')}
                               onChange={(e) => setPhone(e.target.value)} required="required"/>
                        <button className={`btn-defaultsqr ${stateFeedback.loading ? 'loading' : ' '}`}
                                type="submit">{t("Перезвонить вам")}</button>
                    </form>
                </div>
            </div>
        </section>
    )
}

export default Index;
