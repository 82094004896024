import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Swiper, SwiperSlide} from 'swiper/react';
import { Navigation } from "swiper";
import { useMediaQuery } from 'react-responsive'
import first from "../../styles/images/content/application-img.jpg";
import second from "../../styles/images/app-bg-blue.svg";
import third from "../../styles/images/apoint-boss.jpg";
import four from "../../styles/images/content/application-img.jpg";
import five from "../../styles/images/app-bg-yellow.svg";
import six from "../../styles/images/apoint-shipment.jpg";
import seven from "../../styles/images/content/application-img.jpg";
import eight from "../../styles/images/app-bg-red.svg";
import nine from "../../styles/images/apoint-sales.jpg";
import ten from "../../styles/images/content/application-img.jpg";
import eleven from "../../styles/images/app-bg-blue.svg";
import twelwe from "../../styles/images/apoint-sales.jpg";
import 'swiper/css';
import "swiper/css/navigation";

const Index = () => {
    const [activeTab, setActiveTab] = useState(1);
    const [items, setItems] = useState([
        {
            id: 1,
            title: "Apoint Boss",
            image: first,
            bgImage: second,
            appImage: third,
            content_ru: `<p>Apoint Boss - даёт возможность полностью контролировать рабочий процесс удаленно. Теперь в вашем телефоне есть вся информация о Вашей компании:
              </p>
              <ul>
                <li>Продажа;</li>
                <li>Приход на склад;</li>
                <li>Остаток;</li>
                <li>Заказы;</li>
                <li>Расход.</li>
              </ul>`,
            content_uz: ` <p>Apoint Boss - ушбу дастур иловаси сизга иш жараёнини масофадан туриб тўлиқ бошқариш имкониятини беради.
              Энди сизнинг телефонингизда компания ҳақидаги барча маълумотлар бор:
              </p>
              <ul>
                <li>Сотув;</li>
                <li>Омборга киримлар;</li>
                <li>Товар ва маҳсулотларнинг қолдиқлари;</li>
                <li>Буюртмалар;</li>
                <li>Харажатлар.</li>
              </ul>`
        },
        {
            id: 2,
            title: "Apoint Shipment",
            image: four,
            bgImage: five,
            appImage: six,
            content_ru: `
            <p>
             Apoint Shipment - ни один проданный товар не покинет периметр без осмотра что, в свою очередь, не позволяет товарам покинуть предприятие без оплаты.
             </p>
            `,
            content_uz: `
            <p>
              Apoint Shipment - ушбу дастур ҳеч бир сотилган маҳсулотни корхона периметрида текширувсиз қолдирмайди, бу эса ўз навбатида маҳсулотни корхонадан тўловсиз чиқиб кетишига йўл қўймайди.
            </p>
           `
        },
        {
            id: 3,
            title: "Apoint Time",
            image: seven,
            bgImage: eight,
            appImage: nine,
            content_ru: `<p>
              Apoint Time - эта программа отслеживает приход и уход сотрудников в соответствии с установленным графиком работы. Это побуждает сотрудников выполнять обычные рабочие задачи вовремя и оказывает большое положительное влияние на предотвращение потерь клиентов.
            </p><p>Программа также может быть использована для выявления сотрудников, которые приходят на работу вовремя, и поощрения их, и наоборот, для выявления сотрудников, которые приходят регулярно с опозданием, и принятия необходимых мер для повышения их эффективности.</p>`,
            content_uz: `<p>
              Apoint Time - ушбу дастур ишчи ходимларни белгиланган иш графики бўйича келиб-кетишини назорат қилади. Бу эса ходимларни мунтазам иш вазифаларини белгиланган муддатларда бажаришга ундайди ва мижозларни йўқотишни олдини олишга катта ижобий таъсир кўрсатади.
            </p><p>Шунингдек ушбу дастур орқали мунтазам ўз вақтида ишга келаётган ходимларни аниқлаб уларни рағбатлантриш мумкин ва аксинча, мунтазам кеч келаётган ходимларни аниқлаб иш самарадорлигини ошириш мақсадида уларга керакли чора-тадбирларни кўриш мумкин.</p>`
        },
        {
            id: 4,
            title: "Apoint Sales",
            image: ten,
            bgImage: eleven,
            appImage: twelwe,
            content_ru: `<p>
              Apoint Sales - с помощью программы оператор по продажам может создать список продаж, обойдя весь склад или сделав закупку из самой программы и направив клиента в бухгалтерию с чеком.
              </p>
           `,
            content_uz: `<p>
              Apoint Sales - ушбу дастур ёрдамида савдо оператори бутун омборни айланиб чиқиш ёки дастурнинг ўзидан харид қилиш ва мижозни чек билан бухгалтерия бўлимига юбориш орқали сотувлар рўйхатини тузиши мумкин.
              </p>
           `
        }
    ]);
    const {t, i18n} = useTranslation();
    const isDesktopOrLaptop = useMediaQuery({
        query: '(max-width: 767px)'
      })


    const getActiveTab = tabId => {
        const lang = i18n.language;
        const item = items.find(i => i.id === tabId);
        return item[`content_${lang}`];
    };
   
   
    return (
        <section className="applications">
            <div className="container">
                <h2 className="text-title-st">
                    {t("4 приложение поможет вам стать более мобильным")}
                </h2>
                { isDesktopOrLaptop &&<Swiper     onSlideChange={(slider) => setActiveTab(slider.activeIndex + 1)} navigation={true} modules={[Navigation]} spaceBetween={0} slidesPerView={1} className="swiper-wrapper applicatoins-photo">
                    {
                        items.map((item,i) => (
                            <SwiperSlide  key={i}>
                                <div
                            key={item.id}
                            className={`item ${activeTab === i + 1 ? "active" : ""} `}
                            onClick={() => setActiveTab(item.id)}
                        >
                            <h3>{item.title}</h3>
                            <div className="image">
                                <img className="bg" src={item.bgImage} alt="bg"/>
                                <div className="image-item">
                                    <img src={item.appImage} alt="item"/>
                                </div>
                            </div>
                        </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
             }
                { !isDesktopOrLaptop && <div className="swiper-wrapper applicatoins-photo">
                    {items.map((item, i) => (
                        <div
                            key={item.id}
                            className={`item ${activeTab === i + 1 ? "active" : ""} `}
                            onClick={() => setActiveTab(item.id)}
                        >
                            <h3>{item.title}</h3>
                            <div className="image">
                                <img className="bg" src={item.bgImage} alt="bg"/>
                                <div className="image-item">
                                    <img src={item.appImage} alt="item"/>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                }

                <>
                    {activeTab == 1 ? (
                        <div className="applications-desc">
                            <div
                                className="text"
                                dangerouslySetInnerHTML={{__html: getActiveTab(1)}}
                            />
                            <div className="img">
                                <img
                                    src="/assets/images/content/application-img.jpg"
                                    alt="img"
                                />
                            </div>
                        </div>
                    ) : activeTab === 2 ? (
                        <div className="applications-desc">
                            <div
                                className="text"
                                dangerouslySetInnerHTML={{__html: getActiveTab(2)}}
                            ></div>
                            <div className="img">
                                <img
                                    src="/assets/images/content/application-img.jpg"
                                    alt="img"
                                />
                            </div>
                        </div>
                    ) : activeTab === 3 ? (
                        <div className="applications-desc">
                            <div
                                className="text"
                                dangerouslySetInnerHTML={{__html: getActiveTab(3)}}
                            ></div>
                            <div className="img">
                                <img
                                    src="/assets/images/content/application-img.jpg"
                                    alt="img"
                                />
                            </div>
                        </div>
                    ) : activeTab === 4 ? (
                        <div className="applications-desc">
                            <div
                                className="text"
                                dangerouslySetInnerHTML={{__html: getActiveTab(4)}}
                            ></div>
                            <div className="img">
                                <img
                                    src="/assets/images/content/application-img.jpg"
                                    alt="img"
                                />
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                </>
            </div>
        </section>
    );
};

export default Index;
