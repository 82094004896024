import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {get} from "../../helpers/api";
import {Swiper, SwiperSlide} from 'swiper/react';
import { Navigation } from "swiper";
import "swiper/css/navigation";
import 'swiper/css';


const Index = () => {


    const [items, setItems] = useState([])
    const {t, i18n} = useTranslation();

    useEffect(() => {
        get('/reviews?include=photo').then(res => {
            setItems(res.data.data)

        })
            .catch(e => console.log(e))

    }, [i18n.language])
    return (
        <div className="feedbacks">
            <div className="container">
                <h2 className="text-title-nd">{t("Что говорят о нас клиенты")}</h2>
                
                <Swiper navigation={true} modules={[Navigation]} spaceBetween={0} slidesPerView={1} className="feedback-swiper review_swiper my_swiper">
                    {
                        items.map((item, index) => (
                            <SwiperSlide key={index} className=" feedback-swiper-slide eachSlide">
                                {item.photo && <div className="img">
                                    <img src={item.photo.thumbnails.low.src} alt="feed"/>
                                </div>}

                                <div className="text">
                                    <div className="titling">
                                        <div>
                                            <h3>{item.director}</h3>
                                            <p className="position">{item.company_name}</p>
                                        </div>
                                        <div>
                                            <div className="rating"><span></span> <span></span> <span></span>
                                                <span></span> <span className="disabled"></span></div>
                                            <p className="factory">{item.company_type}</p>
                                        </div>
                                    </div>
                                    <p>{item.comment}</p>
                                </div>
                            </SwiperSlide>
                        ))
                    }

                </Swiper>

            </div>
        </div>
    )
}

export default Index;
