import {Swiper, SwiperSlide} from 'swiper/react';
import { Navigation } from "swiper";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import { useMediaQuery } from 'react-responsive'
import {useSelector, useDispatch} from 'react-redux';
import {openModal} from "../../store/slices/feedback";
import first from "../../styles/images/content/application-1.jpg";
import second from "../../styles/images/content/application-2.png";
import third from "../../styles/images/content/application-3.png";
import 'swiper/css';
import "swiper/css/navigation";


const Index = () => {


    const [items, setItems] = useState([
        {
            id: 1,
            title: 'Трикотажные фабрики',
            image: first
        },
        
        {
            id: 2,
            title: 'Производство мебели',
            image: second
        },
        {
            id: 3,
            title: 'Производство DSP и ЛДСП',
            image: third
        }
    ],)
    const isDesktopOrLaptop = useMediaQuery({
        query: '(max-width: 767px)'
      })

    const [slider,setSlider] = useState(isDesktopOrLaptop ? 1 : 3)
    const dispatch = useDispatch()
    const {t, i18n} = useTranslation();

   
    

    const openmyModal = () => {
        dispatch(openModal())
    }
    return (
        <section className="readyprograms">
            <div className="container">
                <h2 className="text-title-nd">{t("Программа уже готово для следуюших направлений")}</h2>
                <Swiper navigation={true} modules={[Navigation]} spaceBetween={20} slidesPerView={slider} className="readyprograms-swiper">

                    {items.map((item, index) => (
                        <SwiperSlide key={index}>
                            <div key={item.id} className="swiper-slide application-item">
                                <div className="item-content">
                                    <img src={item.image} alt="item"/>
                                    <div className="holder">
                                        <p>{t(item.title)}</p>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>

                    ))

                    }

                </Swiper>

                <div className="btn-defaultsqr installapp-btn" onClick={openmyModal}>{t("Установить немедленно")}</div>

            </div>
        </section>
    )
}

export default Index;
