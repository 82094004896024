import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { get } from "../../helpers/api";
import "swiper/css";
import "swiper/css/navigation";
import Melek from "../../../src/styles/images/melek.png";
import Woolontex from "../../../src/styles/images/woolontex.png";
import AziyaArtikul from "../../../src/styles/images/aziya-artikul.png";
import KoxnaBuloq from "../../../src/styles/images/koxna-buloq.png";
import HilolNashr from "../../../src/styles/images/hilol-nashr.png";
import Minmax from "../../../src/styles/images/minmax.png";
import AzizaCake from "../../../src/styles/images/aziza-cake.png";
import Vesbini from "../../../src/styles/images/vesbini.png";
import Ravon from "../../../src/styles/images/ravon.png";
import Triomax from "../../../src/styles/images/triomax.png";
import Azmaro from "../../../src/styles/images/azamaro.png";

const Index = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(max-width: 767px)",
  });
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [slider, setSlider] = useState(isDesktopOrLaptop ? 1 : 2);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    setLoading(true);
    get("posts?include=photo&per_page=20").then((res) => {
      setItems(res.data.data);
      setLoading(false);
    });
  }, [i18n.language]);

  return (
    <section className="news">
      <div className="container">
        <div className="titling">
          <div className="text">
            <h2 className="text-title-nd">{t("Новости и публикации")}</h2>
            <p>
              {t(
                "Все, что касается Apoint и автоматизации бизнес процессов и производства"
              )}
            </p>
          </div>
        </div>
        <Swiper
          navigation={true}
          modules={[Navigation]}
          spaceBetween={20}
          slidesPerView={slider}
          className="news_swiper my_swiper"
        >
          {items.map((item, index) => (
            <SwiperSlide key={index}>
              <>
                <Link className="item-content" to={`/posts/${item.slug}`}>
                  {item.photo && (
                    <div className="img">
                      <img src={item.photo.thumbnails.low.src} alt="news" />
                    </div>
                  )}
                  <p className="title">{item.title}</p>
                </Link>
              </>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <section className="partners">
        <h2 className="partners__title text-title-nd">{t("Нам доверяют")}</h2>
        <div id="infinite" class="partners__slider">
          <div class="custom-container highway-barrier">
            <ul className="partners__list">
              <li className="partners__item">
                <img
                  className="partners__img"
                  src={Melek}
                  alt="partner"
                  width={205}
                  height={100}
                />
              </li>
              <li className="partners__item">
                <img
                  className="partners__img"
                  src={Woolontex}
                  alt="partner"
                  width={300}
                  height={109}
                />
              </li>
              <li className="partners__item">
                <img
                  className="partners__img"
                  src={AziyaArtikul}
                  alt="partner"
                  width={270}
                  height={109}
                />
              </li>
              <li className="partners__item">
                <img
                  className="partners__img"
                  src={KoxnaBuloq}
                  alt="partner"
                  width={307}
                  height={60}
                />
              </li>
              <li className="partners__item">
                <img
                  className="partners__img"
                  src={HilolNashr}
                  alt="partner"
                  width={211}
                  height={58}
                />
              </li>
              <li className="partners__item">
                <img
                  className="partners__img"
                  src={Minmax}
                  alt="partner"
                  width={234}
                  height={58}
                />
              </li>
              <li className="partners__item">
                <img
                  className="partners__img"
                  src={AzizaCake}
                  alt="partner"
                  width={247}
                  height={70}
                />
              </li>
              <li className="partners__item">
                <img
                  className="partners__img"
                  src={Vesbini}
                  alt="partner"
                  width={200}
                  height={70}
                />
              </li>
              <li className="partners__item">
                <img
                  className="partners__img"
                  src={Ravon}
                  alt="partner"
                  width={200}
                  height={120}
                />
              </li>
              <li className="partners__item">
                <img
                  className="partners__img"
                  src={Triomax}
                  alt="partner"
                  width={247}
                  height={70}
                />
              </li>
              <li className="partners__item">
                <img
                  className="partners__img"
                  src={Azmaro}
                  alt="partner"
                  width={247}
                  height={70}
                />
              </li>
            </ul>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Index;
