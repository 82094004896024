import { useEffect } from "react";
import Introduction from "../../components/sections/introduction";
import Offers from "../../components/sections/offers";
 import ContactUs from "../../components/sections/contactUs";
import Achievements from "../../components/sections/achievment";
import Resulsteps from "../../components/sections/resultsteps";
import Applications from "../../components/sections/appliactions";
import Readyprograms from "../../components/sections/readyprograms";
import Reviews from "../../components/sections/reviews";
import News from "../../components/sections/news";
import AOS from 'aos';
import 'aos/dist/aos.css'

const Index = () => {
  
  useEffect(() => {
    AOS.init();
  },[])

    return (
      <div>
             <Introduction/>
              <Offers/>
            <div data-aos="fade-up-left"> <ContactUs/></div>
            <div data-aos="zoom-in-right">  <Achievements/></div>
            <div data-aos="zoom-in-left"> <Resulsteps/></div>
            <div data-aos="zoom-out">  <Applications/></div>
            <div data-aos="zoom-in-down"><Readyprograms/></div>
            <div data-aos="fade-up" data-aos-duration="3000"> <Reviews/></div>
            <div data-aos="zoom-out-down"><News/></div>
        </div>
    )   
}

export default Index;