import {useEffect,  useRef, useState} from "react"
import {Link, useLocation,useNavigate } from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDispatch,useSelector} from 'react-redux';
import {updateLang} from "../store/slices/auth";
import {openModal,update_scroll_position} from "../store/slices/feedback";


const Index = () => {
    
    const feedbackSlice = useSelector(state => state.feedback)
    const history = useNavigate ();
    const dispatch = useDispatch();
    const [isHome, setIsHome] = useState(false);
    const [lang, setLang] = useState('ru')
    const [showNavbar, setshowNavbar] = useState(true);
    const [lastScrollPosition, setlastScrollPosition] = useState(feedbackSlice.scroll_position);
    const header =  useRef()
    const location = useLocation();
    const {t, i18n} = useTranslation();
    const openmyModal = () => {
        dispatch(openModal())
    }
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setLang(lng)
        dispatch(updateLang(lng));
        history('/')

    }
    const onScroll = () => {
        const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
        
        if (currentScrollPosition < 0) {
            return
        }
        if (Math.abs(currentScrollPosition - lastScrollPosition) < 0) {
            return
        }
        
        
        dispatch(update_scroll_position(currentScrollPosition))
        setshowNavbar(currentScrollPosition < lastScrollPosition)


    }
    useEffect(() => {
        setIsHome(location === '/')

    }, [location])

    useEffect(() => {
        window.addEventListener('scroll', onScroll)
        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    }, [])

    const getHeaderClasses = () => {
        let classes = [];
        if (isHome && feedbackSlice.scroll_position > 0) {
            classes.push('not-index')
        };
        if (feedbackSlice.scroll_position > 10){
            classes.push('bg-white')
        };
        if (!showNavbar && feedbackSlice.scroll_position > 200 ){
            classes.push('--hidden')
        }
        if (feedbackSlice.scroll_position < feedbackSlice.prev_scrol) {
           classes =  classes.filter(item => item != '--hidden')
        }
        if (!isHome) classes.push('bg-white')
        return classes.join(" ");
    }

    return (
        <header ref={header} className={getHeaderClasses()}>
            <div className="container navigation">
                <div className="logo">
                    <Link to="/">
                        <img src="/assets/images/icons/logo.svg" alt="logo"/>
                        <img className="mobile" src="/assets/images/icons/logo-mobile.svg" alt="logo"/>
                    </Link>
                </div>
                <div className="links">
                    <ul>
                        <li>
                            <Link
                                className="navigation-links"
                                to="/"
                            >
                                {t('Главная')}
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="navigation-links"
                                to='/posts'
                            >
                                {t("Новости")}
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="additionals">
                    <div className="lang">
                        <div className={`navigation-lang ${lang == 'uz' ? 'current' : ''}`}
                             onClick={() => changeLanguage('uz')}>
                            УЗ
                        </div>
                        <div className={`navigation-lang ${lang == 'ru' ? 'current' : ''}`}
                             onClick={() => changeLanguage('ru')}>
                            RU
                        </div>
                    </div>

                    <div className="install">
                        <button className="btn-default navigation-button" onClick={openmyModal}>Демо версия</button>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Index;
