import {useRef, useState} from "react"
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import InputMask from "react-input-mask";
import {fetchFeedback} from "../store/slices/feedback";
import {update_show_only_success} from "../store/slices/feedback";


const Index = () => {
    const [phone, setPhone] = useState("")
    const {t, i18n} = useTranslation()
    const stateFeedback = useSelector(state => state.feedback)
    const dispatch = useDispatch()
    const phoneRef = useRef()
    const closeSuccessModal = () => {
        dispatch(update_show_only_success(false))
    }

    const onSubmit = (event) => {
        event.preventDefault()
        const data = {
            phone: phone,
            type: 2
        }
        const args = {
            data,
            is_only_success: 'footer'
        }
        dispatch(fetchFeedback(args))
        phoneRef.current.value = ''

    }
    return (
        <footer>
            <section
                className={`modal special_modal consult  ${stateFeedback.show_only_success === 'footer' ? 'show' : ''}`}>
                <div className="modal--overlay" onClick={closeSuccessModal}></div>
                <div className="holder">
                    <div className="content">
                        <div className="success-content">
                            <h3>{t("Заявка успешно отправлено")}</h3><img src="/assets/images/modals/success.png"
                                                                          alt="success"/>
                            <p>{t("Мы очень рады что вы не оставили наше предложение без внимание")}</p>
                            <button className="btn-defaultsqr" onClick={closeSuccessModal}>Ок</button>
                        </div>

                        <div className="modal-close" onClick={closeSuccessModal}></div>
                    </div>
                </div>
            </section>

            <div className="container">
                <h2>{t("Ну что скажите? Автоматизируем?")}</h2>
                <p>{t("Оставьте ваше номер телефона, и мы сами вам позвоним, обясним и подедим")}</p>

                <form onSubmit={onSubmit} className="form">
                    <InputMask mask="99 999 99 99" ref={phoneRef} placeholder={t('Телефон номер')}
                           onChange={(e) => setPhone(e.target.value)} required="required"/>
                    <button className={`btn-defaultsqr ${stateFeedback.loading ? 'loading' : ''}`}
                            type="submit">{t("Перезвонить вам")}</button>
                </form>

                <span className="line"></span>

                <div className="under-content">
                    <Link to="/" className="footer-logo">
                        <img src="/assets/images/icons/logo-footer.svg" alt="logo"/>
                    </Link>

                    <div className="content">

                        <div className="desc">
                            <Link to="/" className="footer-logo-m">
                                <img src="/assets/images/icons/logo-footer.svg" alt="logo"/>
                            </Link>
                            <p>{t("Самый быстрый и простой способ создания растущих бизнес-решений с помощью наших продуктов")}</p>
                        </div>

                        <div className="contacts">
                            <a href="mailto:sales@apoint.uz" className="contact-link">
                                <img src="/assets/images/icons/icon-mail.svg" alt="mail"/>
                                <p>sales@apoint.uz</p>
                            </a>
                            <a href="https://t.me/CEO_APOINT" className="contact-link" target="_blank">
                                <img src="/assets/images/icons/icon-telegram.svg" alt="mail"/>
                                <p>{t("Напишите нам в телеграм")}</p>
                            </a>
                        </div>

                        <div className="phone">
                            <a className="makecall" href="tel:+998781132001">
                                <img src="/assets/images/icons/phone-grey.svg" alt="phone"/>
                                <p><span>+998</span> (78) 113 20 01</p>
                            </a>
                        </div>

                    </div>
                </div>
            </div>

            <div className="outro-content">
                <div className="container">
                    <p>{t("© 2022 Apoint Все права защищены")}</p>
                    <div className="socials">
                        <a href="https://www.instagram.com/apoint.uz/" target="_blank"><img
                            src="/assets/images/icons/social-instagram.svg" alt="social"/></a>
                        <a href="https://www.youtube.com/channel/UCtFKSvAWxFRPnwghQpdz85Q" target="_blank"><img
                            src="/assets/images/icons/icon-youtube.svg" alt="social"/></a>
                        <a href="https://t.me/apoint_uz" target="_blank"><img
                            src="/assets/images/icons/icon-telegram.svg" alt="social"/></a>
                        <a href="https://www.facebook.com/apointuz" target="_blank"><img
                            src="/assets/images/icons/social-facebook.svg" alt="social"/></a>
                    </div>
                </div>
            </div>

        </footer>
    )
};

export default Index;
