import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {get} from "../../helpers/api";
import ContentLoader from "../../components/ContentLoader";

const Index = () => {
    const [newsData, setnewsData] = useState([])
    const [page, setPage] = useState(1)
    const [loading, setloading] = useState(false)
    const {t, i18n} = useTranslation();

    const nextPage = () => {
        setPage(prev => prev + 1)
    }

    

    useEffect(() => {
        window.scroll(0,0)
        setloading(true)
        get('posts?include=photo&per_page=20').then(res => {
            setnewsData(res.data.data)
            setloading(false)
        })
    }, [i18n.language])
    return (
        <section className="newspage ">
            <div className="container">
                <h2 className="text-title-nd">{t("Новости и публикации")}</h2>

                <div className="news-list">
                    {newsData.map((item, index) => (
                        <div className="news-card-wrap" key={item.id}>
                            <Link className="news-card " to={`/posts/${item.slug}`}>
                                {item.photo &&
                                    <div className="news-card__image" v-if="">
                                        <img src={item.photo.thumbnails.low.src} alt="news"/>
                                    </div>
                                }
                                <p className="news-card__title">{item.title}</p>
                            </Link>
                        </div>
                    ))

                    }
                </div>

                {loading &&
                    <div className="loader_wrapper">
                        <ContentLoader className="card_loader" />
                        <ContentLoader className="card_loader" />
                        <ContentLoader className="card_loader" />
                    </div>
                }

                {newsData.meta &&
                    <div className="loadmore">
                        {newsData.meta.currentPage < page &&
                            <div
                                className="btn-loadmore btn-default"
                                onClick={nextPage}
                            >Загрузить еще</div>
                        }
                    </div>
                }
            </div>

        </section>
    )
}

export default Index;
