import { createSlice,createAsyncThunk } from '@reduxjs/toolkit';
import { post } from "../../helpers/api";

const initialState = {
  modalVisible: false,
  loading:false,
  isSuccess:false,
  show_only_success:false,
  scroll_position:0,
  prev_scrol:0

}
export const  fetchFeedback = createAsyncThunk(
  'feedback/fetchFeedback',
    async (args) => {
      let {data} = args
      try {
        const repsonse = await post('feedback',data)
        return repsonse
      } catch (error) {
        throw error
      }
    }

)
export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    openModal: (state) => {
       state.modalVisible = true
       state.isSuccess = false
       state.show_only_success = false
    },
    update_scroll_position (state,action) {
      state.prev_scrol = state.scroll_position
      state.scroll_position = action.payload
       
    },
    closeModal (state) {
        state.modalVisible = false
        state.isSuccess = false
        state.loading = false
    },
    update_show_only_success (state,action) {
      state.show_only_success = action.payload
     
    }
  },
  extraReducers: {
    [fetchFeedback.pending.type]: (state,action) => {
      state.loading = true
      state.isSuccess = false
    },
    [fetchFeedback.fulfilled.type]: (state,action) => {
      state.show_only_success = action.meta.arg.is_only_success;
      state.loading = false
      state.isSuccess = true
    }
  }
})

export const { openModal,closeModal,update_show_only_success,update_scroll_position } = feedbackSlice.actions

export default feedbackSlice.reducer