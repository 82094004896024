
import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  token: [],
  name:'',
  phone:'',
  lang:'ru'
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateLang(state,action) {
        state.lang = action.payload
    }
  }
  
})

export const { updateLang } = authSlice.actions

export default authSlice.reducer